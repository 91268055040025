import { useTranslation } from 'next-i18next';
import { VFC } from 'react';

import CreatorCard from '@/containers/LandingPage/CreatorsSection/CreatorCard';

import { creators } from '../landingPageData';
import styles from './CreatorsSection.module.scss';

const CreatorsSection: VFC = () => {
  const { t } = useTranslation();

  if (creators.length !== 4) {
    return <div>ERROR: 4 creators must be provided!</div>;
  }

  return (
    <section>
      <div className={`container`}>
        <div className={styles.creatorSectionHeader}>
          <h2>{t('landingPage.creators.heading')}</h2>
        </div>
        <ul className={styles.creatorList}>
          {creators.map((creator) => (
            <CreatorCard key={creator.userName} creator={creator} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default CreatorsSection;

import { CreatorData } from '@/containers/LandingPage/interface';
import { convertImgixURL } from '@/utils/imgixUtils';

const getCreatorHeaderImageURL = (fileName: string): string =>
  `https://anifty.imgix.net/landing-page/header-${fileName}`;

export type CreationCardData = {
  aniftyTokenID: number;
  creationNameJA: string | null;
  creationName: string;
  creationImageURL: string;
  creatorNameJA: string | null;
  creatorName: string;
  creatorImageURL: string;
  price: string;
};

export const creators: CreatorData[] = [
  {
    userName: 'yanomitsuki',
    headerImageURL: getCreatorHeaderImageURL('yanomitsuki.jpg'),
    profileImageURL:
      'https://storage.googleapis.com/anifty-media/profile/0xb490dfa23f0b7f6f59e5857e4680dff8ea172814/a55f2f0d8ff94a9bbde90175d7ee617c.png',
    name: 'Yano Mitsuki',
    nameJA: '夜ノみつき',
    description:
      'A popular illustrator who attracts many fans with the smooth lines and gentle color expression.\n' +
      'Yano Mitsuki was involved in a wide range of projects including game character design of "Azure Lane", light novels, and TCG.',
    descriptionJA:
      '滑らかな線と優しい色彩表現で、多くのファンを魅了する人気イラストレーター。\n' +
      '人気ゲーム「アズールレーン」をはじめ、数々のゲームの原画、キャラクターデザインや挿絵など幅広く手掛ける。',
  },
  {
    userName: 'benio',
    headerImageURL: getCreatorHeaderImageURL('benio.png'),
    profileImageURL:
      'https://storage.googleapis.com/anifty-media/profile/0x048ab72990b38332e28d9c2a7bdbc312165573db/f0f72da1fa1445d69fe08432dedb74db.png',
    name: 'Benio',
    nameJA: '紅緒',
    description:
      'Benio is actively involved in illustration and character design for light novels. \n' +
      'Representative works include "I’ve Been Killing Slimes for 300 Years and Maxed Out My Level" and "Is it hard to be a friend character? "Saving by the Numbers! Weak Nation", etc.',
    descriptionJA:
      'ライトノベルの挿画やキャラクターデザインを中心に活動中。\n' +
      '代表作は「スライム倒して300年、知らないうちにレベルMAXになってました」「友人キャラは大変ですか？」「数字で救う！弱小国家」など。',
  },
  {
    userName: 'marumoru',
    headerImageURL: getCreatorHeaderImageURL('marumoru.jpg'),
    profileImageURL:
      'https://storage.googleapis.com/anifty-media/profile/0xa84a0c9ddcd43df115178a3e216f31e53c33e976/4d97a11456814d1daf1982b954f862df.jpg',
    name: 'marumoru',
    nameJA: 'marumoru',
    description:
      'Marumoru is popular for illustrations that emphasize individuality, and provides a wide range of illustrations for books, social games, music videos, and advertising.\n' +
      'Marumoru is the official content creator for the popular games "Arc Knights" and "Genshin Impact".',
    descriptionJA:
      '“個”を強調した雰囲気のイラストで人気を博し、書籍・ソーシャルゲーム、MV用のイラスト制作や広告関係など幅広くイラストを提供している。\n' +
      '人気ゲーム『アークナイツ』『原神』の公式コンテンツクリエイター。',
  },
  {
    userName: 'tomari',
    headerImageURL: getCreatorHeaderImageURL('tomari.png'),
    profileImageURL:
      'https://storage.googleapis.com/anifty-media/profile/0xdd8a60a80a5acf7887783d603f52fe793d1e2d0d/3640e771edeb4123a837a84b9dfb8f37.png\t',
    name: 'Tomari',
    nameJA: 'トマリ',
    description:
      'Tomari is involved in illustration and character design for light novels. \n' +
      'Tomari designed the "Hololive Vtuber Shishiro Botan" and the light novel "My Friend’s Little Sister Has It In for Me", and "Spy Kyoushitu". ',
    descriptionJA:
      'ライトノベルの挿画やキャラクターデザインを中心に活動中。\n' +
      'ホロライブVtuber「獅白ぼたん」やライトノベル「友達の妹が俺にだけウザい」「スパイ教室」のデザインを担当。',
  },
].map((c) => ({
  ...c,
  headerImageURL: convertImgixURL(c.headerImageURL),
  profileImageURL: convertImgixURL(c.profileImageURL),
}));

import { i18n, useTranslation } from 'next-i18next';
import Link from 'next/link';
import { VFC } from 'react';

import { V2CreationData } from '@/apis/aniftyBackend/creations/interface';
import LinkButton from '@/components/CustomButton/LinkButton';
import FramedIcon from '@/components/FramedIcon';
import routes from '@/config/routes';
import { convertImgixURL } from '@/utils/imgixUtils';

import styles from './FeaturedSection.module.scss';

type Props = {
  creationInfo: V2CreationData;
};

const FeaturedSection: VFC<Props> = ({ creationInfo }) => {
  const { t } = useTranslation();

  const convertedImageURL = convertImgixURL(creationInfo.imageURL, {
    width: 1280,
    height: 1280,
    auto: 'compress',
  });

  const sampleIconURL =
    'https://anifty-dev.imgix.net/profile/0x3bbc06e43e088d11621c684e64959a3dba6acfb1/1190be31b12c122bfc8ab5e8fd425c7c73135911.jpg';

  const profileIconURL = creationInfo.creator.profileImageURL || sampleIconURL;

  const title =
    i18n.language == 'ja' && creationInfo.titleJA
      ? creationInfo.titleJA
      : creationInfo.title;

  const creator =
    i18n.language == 'ja' && creationInfo.creator.displayNameJA
      ? creationInfo.creator.displayNameJA
      : creationInfo.creator.displayName;

  return (
    <section>
      <div className={`container ${styles.featuredSection}`}>
        <div className={styles.creationInfo}>
          <h2>{title}</h2>
          <div className={styles.creatorInfo}>
            {t('landingPage.featured.createdBy')}:
            <Link href={routes.myPage(creationInfo.creator.userID)}>
              <a>
                <FramedIcon imageURL={profileIconURL} certified />
                {creator}
              </a>
            </Link>
          </div>
          <div className={styles.navigationButtonContainer}>
            <Link
              href={routes.creationDetail(creationInfo.creationID.toString())}
              passHref
            >
              <LinkButton variant="primary">
                {t('landingPage.featured.viewDetail')}
              </LinkButton>
            </Link>
            <Link href={routes.marketplace} passHref>
              <LinkButton variant="whiteShadow">
                {t('landingPage.featured.browseMarketplace')}
              </LinkButton>
            </Link>
          </div>
        </div>
        <div className={styles.creationImageContainer}>
          <Link
            href={routes.creationDetail(creationInfo.creationID.toString())}
          >
            <a>
              <img
                alt="featured creation"
                src={convertedImageURL}
                className={styles.creationImage}
              />
            </a>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FeaturedSection;

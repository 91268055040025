/**
 * Add imgix params to given url
 *
 * @param imgixURL imgix url
 * @param params object of query params to pass to imgix
 * @return {string} imgix url with custom params
 */
const getImgixURL = (
  imgixURL: string,
  params?: Record<string, string | number>
): string => {
  // convert params to query string
  const joinedParams = params
    ? Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&')
    : '';

  const queryParams = joinedParams.length ? `?${joinedParams}` : '';

  // join URL and query parameters
  return `${imgixURL}${queryParams}`;
};

export default getImgixURL;

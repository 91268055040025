import { formatEther } from '@ethersproject/units';
import { i18n, useTranslation } from 'next-i18next';
import Link from 'next/link';
import { VFC, Fragment } from 'react';

import {
  MintAskData,
  V2CreationData,
} from '@/apis/aniftyBackend/creations/interface';
import AuctionCreationCard from '@/components/AuctionCreationCard';
import SaleCreationCard from '@/components/SaleCreationCard';
import routes from '@/config/routes';
import { DEFAULT_PROFILE_IMAGE_PATH } from '@/constants/images';
import getImgixURL from '@/utils/getImgixURL';
import getThumbnailURL from '@/utils/getThumbnailURL';

import styles from './CreationSection.module.scss';

type Props = {
  creationsInfo: V2CreationData[];
  mintAsksInfo: MintAskData[];
};

const CreationsSection: VFC<Props> = ({ creationsInfo, mintAsksInfo }) => {
  const { t } = useTranslation();

  if (creationsInfo.length !== 4) {
    return <div>ERROR: 4 creations must be provided!</div>;
  }

  const getPriceByAskHash = (askHash: string) => {
    // modified below to avoid the error:
    // TypeError: Cannot read properties of undefined (reading 'askOrder')
    const askOrder = mintAsksInfo.find(
      (mintAsk) => mintAsk.commission.askHash === askHash
    )?.askOrder;

    if (!askOrder) {
      return 'Loading...';
    }

    return formatEther(askOrder.price);
  };

  const getAvailableTokenAmount = (askHash: string): number => {
    const mintAsk = mintAsksInfo.find(
      (mintAsk) => mintAsk.commission.askHash === askHash
    );

    if (!mintAsk) {
      return 0;
    }

    return mintAsk.availableTokenAmount;
  };

  const getTotalTokenAmount = (askHash: string) => {
    const mintAsk = mintAsksInfo.find(
      (mintAsk) => mintAsk.commission.askHash === askHash
    );

    if (!mintAsk) {
      return 0;
    }

    return mintAsk.totalTokenAmount;
  };

  const getHighestBidPrice = (creation: V2CreationData) => {
    if (!creation.mintBids || creation.mintBids.length <= 0) return null;

    const highestBid = creation.mintBids.sort(
      (a, b) => Number(b.price) - Number(a.price)
    )[0];
    return highestBid ? formatEther(highestBid.price) : null;
  };

  return (
    <section>
      <div className="container">
        <div className={styles.creationSectionHeader}>
          <h2>{t('landingPage.creations.heading')}</h2>
          <Link href={routes.marketplace}>
            {t('landingPage.creations.viewMore')}
          </Link>
        </div>
        <div className={styles.creationList}>
          {creationsInfo.map((creation, index) => (
            <Fragment key={creation.creationID}>
              {creation.mintAsk.askType === 0 ? (
                <SaleCreationCard
                  key={index}
                  link={routes.creationDetail(creation.creationID.toString())}
                  creationImageUrl={creation.imageURL}
                  thumbnailUrl={getThumbnailURL(creation.imageURL, {
                    width: creation.width,
                    height: creation.height,
                    cropStartX: creation.cropStartX,
                    cropStartY: creation.cropStartY,
                    cropSize: creation.cropSize,
                  })}
                  creationName={
                    i18n.language === 'ja' && creation.titleJA
                      ? creation.titleJA
                      : creation.title
                  }
                  creatorName={
                    i18n.language === 'ja' && creation.creator.displayNameJA
                      ? creation.creator.displayNameJA
                      : creation.creator.displayName
                  }
                  creatorProfileImageUrl={getImgixURL(
                    creation.creator.profileImageURL ||
                      DEFAULT_PROFILE_IMAGE_PATH
                  )}
                  price={getPriceByAskHash(creation.mintAsk.askHash)}
                  size={260}
                  availableTokenAmount={getAvailableTokenAmount(
                    creation.mintAsk.askHash
                  )}
                  totalTokenAmount={getTotalTokenAmount(
                    creation.mintAsk.askHash
                  )}
                />
              ) : (
                <AuctionCreationCard
                  link={routes.creationDetail(creation.creationID)}
                  creationImageUrl={creation.imageURL}
                  thumbnailUrl={getThumbnailURL(creation.imageURL, {
                    width: creation.width,
                    height: creation.height,
                    cropStartX: creation.cropStartX,
                    cropStartY: creation.cropStartY,
                    cropSize: creation.cropSize,
                  })}
                  creationName={
                    i18n.language === 'ja' && creation.titleJA
                      ? creation.titleJA
                      : creation.title
                  }
                  creatorName={
                    i18n.language === 'ja' && creation.creator.displayNameJA
                      ? creation.creator.displayNameJA
                      : creation.creator.displayName
                  }
                  creatorProfileImageUrl={getImgixURL(
                    creation.creator.profileImageURL ||
                      DEFAULT_PROFILE_IMAGE_PATH
                  )}
                  reservedPrice={getPriceByAskHash(creation.mintAsk.askHash)}
                  endTime={creation.mintAsk.endTime}
                  highestBidPrice={getHighestBidPrice(creation)}
                  status={creation.mintAsk.status}
                />
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CreationsSection;

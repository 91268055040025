import { useTranslation } from 'next-i18next';
import { VFC } from 'react';

import LinkButton from '@/components/CustomButton/LinkButton';

import styles from './LimitedEditionAuction.module.scss';

const LimitedEditionAuction: VFC = () => {
  const { t, i18n } = useTranslation();
  const url =
    i18n.language === 'ja'
      ? 'https://company.anifty.jp/ja/auction'
      : 'https://company.anifty.jp/auction';

  return (
    <div className={styles.LimitedEditionGrid}>
      <div className={styles.LimitedEditionBox}>
        <text className={styles.LimitedEditionHeader}>
          {t('landingPage.limitedAuction.Header')}
        </text>
        <text className={styles.LimitedEditionText}>
          {t('landingPage.limitedAuction.SubHeader')}
        </text>
        <div className={styles.LearnMoreButtonDiv}>
          <LinkButton className={styles.LearnMore} href={url} target="_blank">
            {t('landingPage.limitedAuction.Button')}
          </LinkButton>
        </div>
      </div>
      <div className={styles.LearnImageDiv}>
        <img
          src="/images/home-page/LimitedEditionAuction.png"
          className={styles.LearnImage}
        ></img>
      </div>
    </div>
  );
};

export default LimitedEditionAuction;

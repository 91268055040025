import React, { VFC } from 'react';

import {
  MintAskData,
  V2CreationData,
} from '@/apis/aniftyBackend/creations/interface';
import CreationsSection from '@/containers/LandingPage/CreationsSection';
import CreatorApplicationSection from '@/containers/LandingPage/CreatorApplicationSection';
import CreatorsSection from '@/containers/LandingPage/CreatorsSection';
import HeroSection from '@/containers/LandingPage/HeroSection';

import FeaturedSection from './FeaturedSection';
import IPFSProtected from './IPFSProtected';
import styles from './LandingPage.module.scss';
import LimitedEditionAuction from './LimitedEditionAuction';

type Props = {
  topFeaturedCreationInfo: V2CreationData;
  featuredCreationsInfo: V2CreationData[];
  featuredCreationMintAsks: MintAskData[];
};

const LandingPage: VFC<Props> = ({
  topFeaturedCreationInfo,
  featuredCreationsInfo,
  featuredCreationMintAsks,
}) => {
  return (
    <>
      <HeroSection />
      
      <div className={styles.landingPageContent}>
        {topFeaturedCreationInfo && (
          <FeaturedSection creationInfo={topFeaturedCreationInfo} />
        )}

        {featuredCreationsInfo && featuredCreationMintAsks && (
          <CreationsSection
            creationsInfo={featuredCreationsInfo}
            mintAsksInfo={featuredCreationMintAsks}
          />
        )}

        <CreatorsSection />
        <CreatorApplicationSection />
        <div className={styles.IPFSLimitedGrid}>
          <IPFSProtected />
          <LimitedEditionAuction />
        </div>
      </div>
    </>
  );
};

export default LandingPage;

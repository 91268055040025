import { useTranslation } from 'next-i18next';
import { VFC } from 'react';
import useGetStaticSiteUrl from '@/hooks/useGetStaticSiteUrl';

import LinkButton from '@/components/CustomButton/LinkButton';

import styles from './CreatorApplicationSection.module.scss';

const CreatorApplicationSection: VFC = () => {
  const { t } = useTranslation();
  const getStaticUrl = useGetStaticSiteUrl();

  return (
    <div className={styles.creatorBackground}>
      <div className={styles.creatorSection}>

            <h2>{t('landingPage.creatorApplication.heading')}</h2>
            <p>{t('landingPage.creatorApplication.description')}</p>
            <LinkButton className={styles.registerButton}
              variant="primary"
              href={getStaticUrl('apply')}
            >
              {t('landingPage.creatorApplication.formLink')}
            </LinkButton>
      </div>
    </div>
  );
};

export default CreatorApplicationSection;

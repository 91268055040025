import { VFC } from 'react';
import LinkButton from '@/components/CustomButton/LinkButton';
import styles from './HeroSection.module.scss';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import routes from '@/config/routes';

//{/*routes.marketplace*/}
const HeroSection: VFC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const locale = router.locale;

  return (
    <section className={styles.hero}>
      <h2 className="container">
        <span>Japanese Pop Culture Empowered</span>
        <span>By Blockchain</span>
        <LinkButton className={styles.MarketplaceButton} href= {locale === 'en' ? routes.marketplace: '/' + locale + routes.marketplace}>
                {t('header.marketplace')}
        </LinkButton>
      </h2>
    </section>
  );
};

export default HeroSection;

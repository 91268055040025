import { isPast, parseISO } from 'date-fns';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useMemo, VFC } from 'react';
import Countdown, { zeroPad } from 'react-countdown';

import { MintAskStatusType } from '@/apis/aniftyBackend/mintAsks/interface';
import EthereumIcon from '@/icons/ethereum-icon.svg';

import CreationCardContent from '../CreationCardContent';
import styles from './AuctionCreationCard.module.scss';

type Props = {
  link: string;
  creationImageUrl: string;
  thumbnailUrl: string;
  creationName: string;
  creatorName: string;
  creatorProfileImageUrl: string;
  reservedPrice: string;
  endTime: string | null;
  size?: number;
  highestBidPrice?: string;
  status: MintAskStatusType;
};

const AuctionCreationCard: VFC<Props> = ({
  link,
  creationImageUrl,
  thumbnailUrl,
  creationName,
  creatorName,
  creatorProfileImageUrl,
  reservedPrice,
  endTime,
  size = 330,
  highestBidPrice,
  status,
}) => {
  const { t } = useTranslation();

  const renderer = ({ hours, minutes, seconds }) => (
    <span>
      {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  )

  const displayRemainingTime = useMemo(() => {
    if (status !== MintAskStatusType.OnGoing) {
      return t('auctionCreationCard.finished');
    }

    if (endTime) {
      return isPast(parseISO(endTime)) ? (
        t('auctionCreationCard.expired')
      ) : (
        <Countdown date={parseISO(endTime)} renderer={renderer} />
      );
    } else {
      return '24:00:00';
    }
  }, [endTime, status]);

  return (
    <Link href={link}>
      <a className={styles.item} target="_blank" rel="noreferrer">
        <CreationCardContent
          creationImageUrl={creationImageUrl}
          thumbnailUrl={thumbnailUrl}
          creationName={creationName}
          creatorName={creatorName}
          creatorProfileImageUrl={creatorProfileImageUrl}
          size={size}
          isInverted={true}
        />

        <div className={styles.auctionInfo}>
          <div className={styles.price}>
            <p className={styles.priceLabel}>
              {highestBidPrice
                ? t('auctionCreationCard.bidPrice')
                : t(`auctionCreationCard.reservedPrice`)}
            </p>

            <div className={styles.priceBody}>
              <EthereumIcon />
              <p>{highestBidPrice ?? reservedPrice} WETH</p>
            </div>
          </div>

          <div className={styles.remainingTime}>
            <p className={styles.remainingTimeLabel}>
              {t(`auctionCreationCard.remainingTime`)}
            </p>
            <p className={styles.remainingTimeBody}>{displayRemainingTime}</p>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default AuctionCreationCard;

import { useTranslation } from 'next-i18next';
import { VFC } from 'react';

import LinkButton from '@/components/CustomButton/LinkButton';

import styles from './IPFSProtected.module.scss';

const IPFSProtected: VFC = () => {
  const { t, i18n } = useTranslation();
  const url =
    i18n.language === 'ja'
      ? 'https://company.anifty.jp/ja/ipfs'
      : 'https://company.anifty.jp/ipfs';
  return (
    <div className={styles.IPFSGrid}>
      <div className={styles.ImmunityTextBox}>
        <text className={styles.ImmunityHeader}>
          {t('landingPage.IPSProtected.Header')}
        </text>
        <text className={styles.ImmunityText}>
          {t('landingPage.IPSProtected.SubHeader')}
        </text>
        <div className={styles.LearnMoreButtonDiv}>
          <LinkButton className={styles.LearnMore} href={url} target="_blank">
            {t('landingPage.IPSProtected.Button')}
          </LinkButton>
        </div>
      </div>
      <div className={styles.IPFSImageDiv}>
        <img
          src="/images/home-page/IPFSProtected.png"
          className={styles.IPFSImage}
        ></img>
      </div>
    </div>
  );
};

export default IPFSProtected;

type MintBid = {
  bidID: number;
  bidHash: string;
  askHash: string;
  signer: string; // Bidder address
  price: string; // Wei
  bidAt: string; // ISO8601
  v: number;
  r: string;
  s: string;
};

export type MintBidWithUser = MintBid & {
  user: {
    userID: string;
    profileImageURL: string | null;
    userName: string | null;
    displayName: string | null;
    displayNameJA: string | null;
  };
};

export type GetMintBidsResponse = MintBidWithUser[];

export const ListingType = {
  Sale: 0,
  Auction: 1,
} as const;

export type ListingType = typeof ListingType[keyof typeof ListingType];

export const MintAskStatusType = {
  OnGoing: 1,
  Finished: 2,
  Cancelled: 3,
} as const;

export type MintAskStatusType =
  typeof MintAskStatusType[keyof typeof MintAskStatusType];

import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { VFC } from 'react';

import EthereumIcon from '@/icons/ethereum-icon.svg';

import CreationCardContent from '../CreationCardContent';
import styles from './SaleCreationCard.module.scss';

type Props = {
  link: string;
  creationImageUrl: string;
  thumbnailUrl?: string;
  creationName: string;
  creatorName: string;
  creatorProfileImageUrl: string;
  price: string;
  availableTokenAmount: number;
  totalTokenAmount: number;
  size?: number;
};

const SaleCreationCard: VFC<Props> = ({
  link,
  creationImageUrl,
  thumbnailUrl,
  creationName,
  creatorName,
  creatorProfileImageUrl,
  price,
  availableTokenAmount,
  totalTokenAmount,
  size = 330,
}) => {
  const { t } = useTranslation();
  return (
    <Link href={link}>
      <a className={styles.item} target="_blank" rel="noreferrer">
        <CreationCardContent
          creationImageUrl={creationImageUrl}
          thumbnailUrl={thumbnailUrl}
          creationName={creationName}
          creatorName={creatorName}
          creatorProfileImageUrl={creatorProfileImageUrl}
          size={size}
        />

        <div className={styles.saleInfo}>
          <div className={styles.price}>
            <p className={styles.priceLabel}>{t(`saleCreationCard.price`)}</p>

            <div className={styles.priceBody}>
              <EthereumIcon />
              <p>{price} ETH</p>
            </div>
          </div>

          <div className={styles.amount}>
            <p className={styles.amountLabel}>
              {t(`saleCreationCard.supplies`)}
            </p>
            <p
              className={styles.amountBody}
            >{`${availableTokenAmount} / ${totalTokenAmount}`}</p>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default SaleCreationCard;

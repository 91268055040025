import getCropParams from '@/utils/getCropParams';
import { convertImgixURL } from '@/utils/imgixUtils';
import isGif from '@/utils/isGif';

type GetThumbnailURLParams = {
  width: number;
  height: number;
  cropStartX: number;
  cropStartY: number;
  cropSize: number;
};

const getThumbnailURL = (
  imageURL: string,
  params: GetThumbnailURLParams
): string => {
  if (isGif(imageURL)) {
    // animation gif cannot be processed with imgix
    return imageURL;
  }

  const { width, height, ...cropData } = params;

  return convertImgixURL(
    imageURL,
    getCropParams(
      {
        width,
        height,
      },
      cropData
    )
  );
};

export default getThumbnailURL;

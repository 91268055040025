import { GetStaticProps, NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import fetchMintAskByAskHash from '@/apis/aniftyBackend/creations/fetchMintAskByAskHash';
import {
  MintAskData,
  V2CreationData,
} from '@/apis/aniftyBackend/creations/interface';
import v2FetchCreationByID from '@/apis/aniftyBackend/creations/v2FetchCreationByID';
import LandingPage from '@/containers/LandingPage';
import { captureException } from '@/utils/Sentry';
import { getLPConfig } from '@/utils/getLPConfig';

type Props = {
  topFeaturedCreationInfo: V2CreationData;
  featuredCreationsInfo: V2CreationData[];
  featuredCreationMintAsks: MintAskData[];
};

const Home: NextPage<Props> = ({
  topFeaturedCreationInfo,
  featuredCreationsInfo,
  featuredCreationMintAsks,
}) => {
  return (
    <LandingPage
      topFeaturedCreationInfo={topFeaturedCreationInfo}
      featuredCreationsInfo={featuredCreationsInfo}
      featuredCreationMintAsks={featuredCreationMintAsks}
    />
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  let topFeaturedCreationInfo = null;
  let featuredCreationsInfo: V2CreationData[] | null = [];
  let featuredCreationMintAsks: MintAskData[] | null = [];

  const lpConfig = getLPConfig();

  try {
    topFeaturedCreationInfo = await v2FetchCreationByID(
      lpConfig.featuredCreationID
    );

    featuredCreationsInfo = await Promise.all(
      lpConfig.pickupCreationIDs.map((id) => v2FetchCreationByID(id))
    );

    featuredCreationMintAsks = await Promise.all(
      featuredCreationsInfo.map((creation) =>
        fetchMintAskByAskHash(creation.mintAsk.askHash)
      )
    );
  } catch (err) {
    captureException(err);

    // FIXME: update to v2 creation data
    // topFeaturedCreationInfo = mockAniftyTokenDetails;

    // for (let i = 0; i < featuredCreationIDs.length; i++) {
    //   featuredCreationsInfo.push(mockAniftyTokenDetails);
    // }
  }

  return {
    props: {
      topFeaturedCreationInfo,
      featuredCreationsInfo,
      featuredCreationMintAsks,
      ...(await serverSideTranslations(locale, ['common'])),
    },
    revalidate: 60,
  };
};

export default Home;

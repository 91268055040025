import Image from 'next/image';
import { VFC } from 'react';

import generateSquareImageURL from '@/utils/generateSquareImageURL';
import { convertImgixURL, getBlurImageURL } from '@/utils/imgixUtils';
import isGif from '@/utils/isGif';

import FramedIcon from '../FramedIcon';
import styles from './CreationCardContent.module.scss';

type Props = {
  creationImageUrl: string;
  thumbnailUrl?: string;
  creationName: string;
  creatorName: string;
  creatorProfileImageUrl: string;
  size?: number;
  isInverted?: boolean; // determine if letter color is inverted
};

const CreationCardContent: VFC<Props> = ({
  creationImageUrl,
  thumbnailUrl,
  creationName,
  creatorName,
  creatorProfileImageUrl,
  size = 330,
  isInverted = false,
}) => {
  return (
    <div className={styles.itemBody}>
      <div className={styles.itemImageContainer}>
        {/* Empty div for square space */}
        <div className={styles.itemImageArea} />
        {/* Spread the content all over the div */}
        <div className={styles.itemImageContent}>
          {/* Use Next/Image if image is GIF because imgix does not support animated GIF */}
          {isGif(creationImageUrl) ? (
            <Image
              src={creationImageUrl}
              layout="fill"
              placeholder="blur"
              blurDataURL={getBlurImageURL(creationImageUrl)}
              objectFit="cover"
              className={styles.creationImage}
            />
          ) : (
            <img
              alt="token image"
              src={
                isGif(creationImageUrl)
                  ? creationImageUrl
                  : thumbnailUrl ||
                    generateSquareImageURL(
                      convertImgixURL(creationImageUrl),
                      size
                    )
              }
            />
          )}
        </div>
      </div>

      <div className={styles.itemDescription}>
        <p
          className={
            isInverted ? styles.creationNameInverted : styles.creationName
          }
        >
          {creationName}
        </p>
        <div className={styles.itemCreator}>
          <FramedIcon imageURL={creatorProfileImageUrl} size={20} />
          <span
            className={
              isInverted ? styles.creatorNameInverted : styles.creatorName
            }
          >
            {creatorName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CreationCardContent;

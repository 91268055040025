import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { VFC } from 'react';

import FramedIcon from '@/components/FramedIcon';
import routes from '@/config/routes';
import { CreatorData } from '@/containers/LandingPage/interface';
import getImgixURL from '@/utils/getImgixURL';

import styles from './CreatorsSection.module.scss';

type Props = {
  creator: CreatorData;
};

const CreatorCard: VFC<Props> = ({ creator }: Props) => {
  const { i18n } = useTranslation();

  const description =
    i18n.language === 'ja' ? creator.descriptionJA : creator.description;

  const name = i18n.language == 'ja' ? creator.nameJA : creator.name;

  return (
    <Link href={routes.creatorDetail(creator.userName)}>
      <a className={styles.creatorCard}>
        <img
          src={getImgixURL(creator.headerImageURL, {
            w: 528, // 2x 264
            h: 310, // 2x 155
            fit: 'crop',
          })}
          alt="creator header"
        />
        <div>
          <FramedIcon imageURL={creator.profileImageURL} certified />
          <div>{name}</div>
          <p>{description}</p>
        </div>
      </a>
    </Link>
  );
};

export default CreatorCard;

import classNames from 'classnames';
import { forwardRef, HTMLProps, VFC } from 'react';

import { ButtonVariant } from '@/components/CustomButton/index';

import styles from './CustomButton.module.scss';

type Props = HTMLProps<HTMLAnchorElement> & {
  variant?: ButtonVariant;
};

const LinkButton: VFC<Props> = forwardRef(
  ({ variant, className, children, ...restProps }: Props, ref) => {
    const classes = classNames(
      [styles.customButton, styles[variant]],
      className
    );

    return (
      <a className={classes} {...restProps} {...restProps} ref={ref}>
        {children}
      </a>
    );
  }
);

export default LinkButton;

// correspondence table between bucket names and subdomains
const imgixSources = {
  'anifty-media-dev': 'anifty-dev',
  'anifty-media': 'anifty',
  'anifty-images': 'anifty-dev', // old `anifty-dev` contents are copied to `anifty-media-dev`
};

/**
 * Get imgix host by storage bucket name using correspondence table
 *
 * @param bucketName bucket name
 * @returns {string} imgix host name
 */
export const getImgixHost = (bucketName: string): string => {
  const subdomain = imgixSources[bucketName] || imgixSources['anifty-media'];
  return `https://${subdomain}.imgix.net`;
};

/**
 * Parse public url and returns array pair of bucket name and file name
 *
 * @param storageURL
 * @return {[string, string]} [bucketName, fileName]
 */
export const parseStorageURL = (storageURL: string): [string, string] => {
  const regexp = /https:\/\/storage\.googleapis\.com\/(.+?)\/(.+)/;
  const matches = storageURL.match(regexp);

  if (!matches || matches.length !== 3) {
    throw new Error(
      `[parseStorageURL] given URL ( ${storageURL} ) is invalid structure`
    );
  }

  return [matches[1], matches[2]];
};

/**
 * Convert storage public url to imgix optimized url
 *
 * @param storageURL url of master image url in cloud storage
 * @param params object of query params to pass to imgix
 * @return {string} converted imgix url
 */
export const convertImgixURL = (
  storageURL: string,
  params?: Record<string, string | number>
): string => {
  let bucketName, fileName;

  try {
    // parse storage public url
    [bucketName, fileName] = parseStorageURL(storageURL);
  } catch (err) {
    // return original URL if failed to parse
    return storageURL;
  }

  // get imgix host
  const imgixHost = getImgixHost(bucketName);

  // convert params to query string
  const joinedParams = params
    ? Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&')
    : '';
  const queryParams = joinedParams.length ? `?${joinedParams}` : '';

  // join host, filename and query parameters
  return `${imgixHost}/${fileName}${queryParams}`;
};

export const getBlurImageURL = (src: string): string =>
  convertImgixURL(src, { w: 150, auto: 'compress' });

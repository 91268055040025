type ImageData = {
  width: number;
  height: number;
};

type CropData = {
  cropStartX: number;
  cropStartY: number;
  cropSize: number;
  outputSize?: number;
};

type ImgixCropParams = {
  w: number;
  h: number;
  fit: 'crop';
  crop: 'focalpoint';
  'fp-x': number;
  'fp-y': number;
  'fp-z': number;
  auto: 'compress';
};

const getDefaultParams = (outputSize = 660): ImgixCropParams => ({
  w: outputSize,
  h: outputSize,
  fit: 'crop',
  crop: 'focalpoint',
  'fp-x': 0.5,
  'fp-y': 0.5,
  'fp-z': 1,
  auto: 'compress',
});

const roundFloat = (num: number): number => {
  return Math.round(num * 100) / 100;
};

const getCropParams = (
  imageData: ImageData,
  cropData: CropData
): ImgixCropParams => {
  const shortSide = Math.min(imageData.width, imageData.height);

  // check if crop data is valid
  if (cropData.cropStartX + cropData.cropSize > imageData.width) {
    // x position exceeds image width
    return getDefaultParams(cropData.outputSize);
  }

  if (cropData.cropStartY + cropData.cropSize > imageData.height) {
    // y position exceeds image height
    return getDefaultParams(cropData.outputSize);
  }

  const fpX = (cropData.cropStartX + cropData.cropSize / 2) / imageData.width;
  const fpY = (cropData.cropStartY + cropData.cropSize / 2) / imageData.height;
  const fpZ = shortSide / cropData.cropSize;

  return {
    w: cropData.outputSize || 660,
    h: cropData.outputSize || 660,
    fit: 'crop',
    crop: 'focalpoint',
    'fp-x': roundFloat(fpX),
    'fp-y': roundFloat(fpY),
    'fp-z': roundFloat(fpZ),
    auto: 'compress',
  };
};

export default getCropParams;

import { VFC } from 'react';

import { DEFAULT_PROFILE_IMAGE_PATH } from '@/constants/images';
import CertifiedBadgeIcon from '@/icons/certified-badge.svg';
import generateSquareImageURL from '@/utils/generateSquareImageURL';

import styles from './FramedIcon.module.scss';

type Props = {
  imageURL: string | null;
  size?: number;
  certified?: boolean;
};

const FramedIcon: VFC<Props> = ({
  imageURL: providedImageURL,
  size = 32,
  certified,
}: Props) => {
  const iconSize = size - 2;

  const imageURL = providedImageURL ?? DEFAULT_PROFILE_IMAGE_PATH;

  return (
    <div className={styles.framedIcon} style={{ width: size, height: size }}>
      <img
        src={generateSquareImageURL(imageURL, size * 2)}
        alt="profile icon"
        style={{ width: iconSize, height: iconSize }}
      />
      {certified && <CertifiedBadgeIcon className={styles.certifiedBadge} />}
    </div>
  );
};

export default FramedIcon;

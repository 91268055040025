import getImgixURL from '@/utils/getImgixURL';

/**
 * Generate square image url using imgix
 *
 * @param imgixURL profile image URL (must be an imgix URL)
 * @param size image size in px (default: 100px)
 * @return {string} generated url
 */
const generateSquareImageURL = (imgixURL: string, size = 100): string => {
  return getImgixURL(imgixURL, { w: size, h: size, fit: 'crop' });
};

export default generateSquareImageURL;
